import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

/*** SENTRY ***/
/*
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://ed9ae65d2b929a80785099e00bc7edcb@o4507963214397440.ingest.us.sentry.io/4508305709924352",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api.beambenders\.com/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

*/
/*** SENTRY ***/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
